import { Suspense } from 'react'
import { BlitzPage, invoke, Routes, useMutation, useSession } from 'blitz'
import { Button, Card, Layout } from '@shopify/polaris'
import { AdminLayout } from '@/core/layouts/AdminLayout'
import { useCurrentUser } from '@/hooks/useCurrentUser'
import logout from '../auth/mutations/logout'
import LoadMockProducts from '../products/mutations/LoadMockProducts'
import { NeverInProduction } from '@/core/components/NeverInProduction'
import CatalogAudit from '../audits/components/catalogAudit'
import { LoadingSpinner } from '@/core/components/LoadingSpinner'

const debug = require('debug')('lf:admin')

const Sesh = () => {
  const currentUser = useCurrentUser()
  const sesh = useSession()
  const [logoutMutation] = useMutation(logout)

  const combined = {
    ...currentUser,
    ...sesh
  }
  // debug('currentUser + sesh')

  function loadFakeProducts() {
    invoke(LoadMockProducts, {})
    console.log('load em')
  }

  return (
    <div className="row">
      <div className="col-lg-9">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <p className="panel-info">
                <strong>{combined.name}</strong>
              </p>
              <p className="panel-info">{combined.email}</p>

              <br />

              <Button
                onClick={async () => {
                  await logoutMutation()
                }}>
                Logout
              </Button>
              <NeverInProduction>
                <Button onClick={loadFakeProducts}>Load Fake Products</Button>
              </NeverInProduction>
            </Card>

            {/* Catalog audit cards */}

            <CatalogAudit />
          </Layout.Section>
        </Layout>
      </div>
    </div>
  )
}

const HomePage: BlitzPage = () => {
  return (
    <>
      <div className="customizer-landing">
        <div className="customizer-panel customizer-panel-main">
          <Suspense fallback={<LoadingSpinner />}>
            <Sesh />
          </Suspense>
        </div>
      </div>
    </>
  )
}

HomePage.suppressFirstRenderFlicker = true
HomePage.getLayout = (page) => <AdminLayout title="Admin">{page}</AdminLayout>
HomePage.authenticate = { redirectTo: Routes.LoginPage() }

export default HomePage
