import { useEnvironment } from '@/hooks/useEnvironment'

export function NeverInProduction({ children }) {
  const { is_development } = useEnvironment()

  if (is_development) {
    return children
  } else {
    return null
  }
}
