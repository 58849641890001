export const THEME_ID = process.env.NEXT_PUBLIC_SHOPIFY_THEME_ID!
export const SHOPIFY_SHOP_NAME = process.env.NEXT_PUBLIC_SHOPIFY_SHOP_NAME!
export const SHOPIFY_ACCESS_TOKEN = process.env.SHOPIFY_ACCESS_TOKEN!
export const CUSTOM_PRODUCTS_COLLECTION_ID = process.env.NEXT_PUBLIC_CUSTOM_PRODUCTS_COLLECTION_ID!
export const FIREBASE_DATABASE_URL = process.env.FIREBASE_DATABASE_URL!
export const GIT_BRANCH = process.env.VERCEL_GIT_COMMIT_REF!
export const ENABLE_CUSTOMIZER_TAG = process.env.ENABLE_CUSTOMIZER_TAG! || 'LFC-DEVELOP'

export const useEnvironment = () => {
  const is_development =
    process.env.VERCEL_ENV === 'preview' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
    process.env.BLITZ_PUBLIC_VERCEL_ENV === 'preview' ||
    process.env.NODE_ENV === 'development'

  return {
    is_development,
    theme_id: THEME_ID,
    shop_name: SHOPIFY_SHOP_NAME
  }
}
