import { useQuery } from 'blitz'
import GetProductAudit from '../queries/GetProductAudit'
import GetPatchAudit from '../queries/GetPatchAudit'
import { useMemo } from 'react'
import { ProductAudit, ProductMissingFabricColor } from '../lib/auditProduct'
import { PatchAudit } from '../lib/auditPatch'

const debug = require('debug')('lf:audit')

type AuditsByProductId = { [id: string]: ProductAudit[] }
type AuditsByPatchId = { [id: string]: PatchAudit[] }

export const useAudits = () => {
  const [productAudits, productAuditsMeta] = useQuery(GetProductAudit, {})
  const [patchAudits, patchAuditsMeta] = useQuery(GetPatchAudit, {})

  const alertsByProductId = useMemo(() => {
    const pid: AuditsByProductId = {}
    productAudits
      .filter((pa) => pa.auditResult !== 'NoError')
      .forEach((pa) => {
        const current = pid[pa.product.id] || ([] as ProductAudit[])
        pid[pa.product.id] = [...current, pa]
      })
    debug('alertsByProductId', pid)
    return pid
  }, [productAudits])

  const alertsByPatchId = useMemo(() => {
    const pid: AuditsByPatchId = {}
    patchAudits
      .filter((pa) => pa.auditResult !== 'NoError')
      .forEach((pa) => {
        const current = pid[pa.shopifyProduct.id] || ([] as PatchAudit[])
        pid[pa.shopifyProduct.id] = [...current, pa]
      })
    debug('alertsByPatchId', pid)
    return pid
  }, [patchAudits])

  const missingFabricColors = useMemo(() => {
    const colors = new Set(
      productAudits
        .filter((pa) => pa.auditResult === 'ProductMissingFabricColor')
        .map((pa: ProductMissingFabricColor) => pa.color)
    )
    debug('missingFabricColors', colors)
    return Array.from(colors)
  }, [productAudits])

  return { productAudits, patchAudits, alertsByProductId, alertsByPatchId, missingFabricColors }
}
