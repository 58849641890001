import { useCallback } from 'react'
import { Router, Routes } from 'blitz'
import { Banner, List } from '@shopify/polaris'
import { PatchNotConfigured, PatchVariantNotConfigured } from '../lib/auditPatch'

const debug = require('debug')('lf:audit')

export function PatchAlertCard({ alerts }) {
  const title = alerts[0].shopifyProduct.title

  const renderedPatchAudits = alerts.flatMap((auditResult) => {
    switch (auditResult.auditResult) {
      case 'PatchNotConfigured':
        return <PatchNotConfiguredCard key={auditResult.shopifyProduct.id + auditResult.color} {...auditResult} />
      case 'PatchVariantNotConfigured':
        return (
          <PatchVariantNotConfiguredCard
            key={auditResult.shopifyProduct.id + auditResult.shopifyVariant.id}
            {...auditResult}
          />
        )
      default:
        return null
    }
  })

  const handleWarningAction = useCallback(() => Router.push(Routes.PatchesPage()), [])

  return (
    <Banner
      title={`Alerts for ${title}`}
      status="warning"
      action={{
        content: 'Review',
        onAction: handleWarningAction
      }}>
      {renderedPatchAudits}
    </Banner>
  )
}

function PatchVariantNotConfiguredCard(auditResult: PatchVariantNotConfigured) {
  return (
    <div className="section">
      <p>
        <strong>Patch dimensions not configured</strong>
      </p>

      <List>
        <List.Item>Product: {auditResult.shopifyProduct.title}</List.Item>
        <List.Item>Variant: {auditResult.shopifyVariant.title}</List.Item>
      </List>
    </div>
  )
}

function PatchNotConfiguredCard(auditResult: PatchNotConfigured) {
  return (
    <div className="section">
      <p>
        <strong>New Shopify Patch</strong>
      </p>

      <List>
        <List.Item>Product: {auditResult.shopifyProduct.title}</List.Item>
      </List>
    </div>
  )
}
