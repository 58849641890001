import { useCallback } from 'react'
import { Router, Routes } from 'blitz'
import { Banner } from '@shopify/polaris'

export function FabricColorAlertCard({ color }) {
  const handleWarningAction = useCallback(() => Router.push(Routes.FabricColorsPage()), [])

  return (
    <Banner
      status="warning"
      action={{
        content: 'Review',
        onAction: handleWarningAction
      }}>
      No fabric color for {color}
    </Banner>
  )
}
