import { Card } from '@shopify/polaris'
import _ from 'lodash'
import { useAudits } from '../hooks/useAudits'
import { ProductAlertCard } from './productAlertCard'
import { PatchAlertCard } from './patchAlertCard'
import { FabricColorAlertCard } from './fabricColorAlertCard'

const debug = require('debug')('lf:audit')

export default function CatalogAudit() {
  const { alertsByPatchId, alertsByProductId, missingFabricColors } = useAudits()

  const productAlertCards = _.map(alertsByProductId, (alerts, id) => {
    return <ProductAlertCard key={id} alerts={alerts} />
  })

  const patchAlertCards = _.map(alertsByPatchId, (alerts, id) => {
    return <PatchAlertCard key={id} alerts={alerts} />
  })

  const fabricColorAlertCards = _.map(missingFabricColors, (color, id) => {
    return <FabricColorAlertCard key={color} color={color} />
  })

  return (
    <>
      <Card>{productAlertCards}</Card>
      <Card>{patchAlertCards}</Card>
      <Card>{fabricColorAlertCards}</Card>
    </>
  )
}
